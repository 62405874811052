import FrontPageEnum from '../enumerators/front-page.enum'

export default class HomeService {
    constructor() {
        if ($(FrontPageEnum.STRATE_BLOG_SLIDER).length > 0) {
            import(
                '@scripts/components/front-page/strate-blog.component' /* webpackChunkName: "scripts/strate-blog-component" */
            ).then(({ default: StrateBlogComponent }) => {
                new StrateBlogComponent()
            })
        }

        if ($(FrontPageEnum.STRATE_EVENT_SLIDER).length > 0) {
            import(
                '@scripts/components/front-page/strate-event.component' /* webpackChunkName: "scripts/strate-event-component" */
            ).then(({ default: StrateEventComponent }) => {
                new StrateEventComponent()
            })
        }

        if ($(FrontPageEnum.STRATE_SOCIAL_WALL).length > 0) {
            import(
                '@scripts/components/front-page/strate-social.component' /* webpackChunkName: "scripts/strate-social-component" */
            ).then(({ default: StrateSocialComponent }) => {
                new StrateSocialComponent()
            })
        }

        if (
            window.matchMedia('(min-width: 1001px)').matches &&
            $(FrontPageEnum.PARALLAX_ELEMENT).length > 0
        ) {
            import(
                '@scripts/components/front-page/animations/parallax.component' /* webpackChunkName: "scripts/parallax.component" */
            ).then(({ default: ParallaxComponent }) => {
                new ParallaxComponent()
            })
        }

        if (
            window.matchMedia('(min-width: 1001px)').matches &&
            $(FrontPageEnum.REVEAL_ELEMENT).length > 0
        ) {
            import(
                '@scripts/components/front-page/animations/reveal.component' /* webpackChunkName: "scripts/reveal.component" */
            ).then(({ default: RevealComponent }) => {
                new RevealComponent()
            })
        }
    }
}
