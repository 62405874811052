export default class FrontPageEnum {
    static STRATE_BLOG_SLIDER = '[data-element="strate-blog-slider"]'
    static STRATE_BLOG_SLIDER_NAVIGATION_NEXT = '[data-customer-navigation="blog-next"]'
    static STRATE_BLOG_SLIDER_NAVIGATION_PREV = '[data-customer-navigation="blog-prev"]'

    static STRATE_EVENT_SLIDER = '[data-element="strate-event-slider"]'
    static STRATE_EVENT_SLIDER_NAVIGATION_NEXT = '[data-customer-navigation="event-next"]'
    static STRATE_EVENT_SLIDER_NAVIGATION_PREV = '[data-customer-navigation="event-prev"]'

    static STRATE_SOCIAL_WALL = '[data-element="social-wall"]'

    // Animations
    static PARALLAX_ELEMENT = '[data-element="parallax"]'
    static REVEAL_ELEMENT = '[data-element="reveal"]'
    static REVEAL_TARGET = '[data-reveal-target]'
}
